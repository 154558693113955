import { Component } from 'react';

class Comissao extends Component{

	componentWillMount(){
	  	var codigo = this.props.match.params.codigo

	  	var comissoes = JSON.parse(localStorage.getItem("comissoes"))
	  	if(!comissoes){
	  		comissoes = {}
	  	}

	    fetch(window.siteurl + "/produto/comissao/verificar/" + codigo)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          if(result.id_produto){
	          	comissoes[result.id_produto] = result.id_vendedor

	          	localStorage.setItem("comissoes", JSON.stringify(comissoes))
	            this.props.history.push("/produto/ver/"+result.id_produto)
	          }
	          else{
	            this.props.history.push("/")
	          }
	        },
	        (error) => {
	          alert(error)
	        }
	      )
	   }

	render(){
		return null
	}
}

export default Comissao;