import React, { Component } from 'react';
import Navbar from '../partials/Navbar';


class CadastroCategoria extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario || !usuario.admin){
          this.props.history.push('/')
      }
   }

   cadastrar(event){
    var apitoken = localStorage.getItem("apitoken")

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    //const data = JSON.stringify(Object.fromEntries(formData));

    var object = {};
    formData.forEach((value, key) => {object[key] = value});
    var data = JSON.stringify(object);

    fetch(window.siteurl + "/categoria/cadastrar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/')
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <h1>Cadastrar categoria</h1>
            <form onSubmit={this.cadastrar}>
                  <label htmlFor="nome">Nome</label><br/>
                  <input id="nome" name="nome" required size="32" type="text"/><br/>
                  <label htmlFor="descricao">Descrição</label>
                  <textarea id="descricao" name="descricao" className="materialize-textarea"></textarea>
                  <button className="btn waves-effect waves-light red accent-4" type="submit" id="enviar" name="action">
                          Cadastrar
                          <i className="material-icons right">send</i>
                  </button>
          </form>
        </div>
      </div>
   );
   }
}


export default CadastroCategoria;