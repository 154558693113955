import React, { Component } from 'react';
import Navbar from '../partials/Navbar';

class CadastroUsuario extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);
  }

  componentWillMount(){
      var token = localStorage.getItem("apitoken")

      if(token){
          this.props.history.push('/')
      }
   }

   cadastrar(event){
    event.preventDefault();

    var este = this
    
    var formData = new FormData(event.target);

    //const data = JSON.stringify(Object.fromEntries(formData));

    var object = {};
    formData.forEach((value, key) => {object[key] = value});
    var data = JSON.stringify(object);

    fetch(window.siteurl + "/usuario/cadastrar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/usuario/login')
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <h1>Registrar</h1>
            <form onSubmit={this.cadastrar}>
              <label htmlFor="nome">Nome completo</label><br/>
              <input id="nome" name="nome" required size="32" type="text" /><br/>

              <label htmlFor="cpf">CPF</label><br/>
              <input id="cpf" name="cpf" required size="32" type="text" /><br/>

              <label htmlFor="email">Email</label><br/>
              <input id="email" name="email" required size="32" type="email" /><br/>

              <label htmlFor="nome">Celular</label><br/>
              <input id="celular" name="celular" required size="32" type="text" /><br/>

              <label htmlFor="endereco">Endereço</label><br/>
              <input id="endereco" name="endereco" required size="32" type="text" /><br/>

              <label htmlFor="password">Senha</label><br/>
              <input id="password" name="password" required size="32" type="password" /><br/>

              <label htmlFor="password2">Confirmar senha</label><br/>
              <input id="password2" name="password2" required size="32" type="password" /><br/>
              <button className="btn waves-effect waves-light red accent-4" id="submit" type="submit">
                  Registrar<i className="material-icons right">send</i>
              </button>
            </form>
        </div>
      </div>
   );
   }
}

export default CadastroUsuario;