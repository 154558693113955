import React from 'react';
import { Link } from 'react-router-dom';

import M from "materialize-css";

class VerVendas extends React.Component{
	constructor() {
	  super();
	  this.state = {vendas: null};
	}
    componentDidMount(){
		var apitoken = localStorage.getItem("apitoken")

		fetch(window.siteurl + "/venda/listar/vendas", {
              headers: {
                'Authorization': 'Bearer '+apitoken
              },
            })
		  .then(res => res.json())
		  .then(
		    (result) => {
		      if(result.vendas){
		      		if(result.vendas.length > 0){
			            this.setState({
			               vendas: result.vendas
			            })
		        	}
		      }
		    },
		    (error) => {
		      alert(error)
		    }
		  )
    }

    initToolbar(){
    	var elems = document.querySelectorAll('.fixed-action-btn');
		var instances = M.FloatingActionButton.init(elems, {
				toolbarEnabled: true
		});

	   var elems = document.querySelectorAll('.tooltipped');
	   var instances = M.Tooltip.init(elems, {});
    }

	render(){
		const { vendas } = this.state
		return(
			  <React.Fragment>
					<div id="vendas" className="modal">
					<div className="modal-content center-align red darken-3">
					    <div className="right-align">
					      <a href="#!" className="modal-close btn-flat white black-text"><i className="material-icons center">close</i></a>
					    </div>
					    <h4>Minhas vendas</h4>
					    <div className="row" style={{height:"100%", overflow:"auto", width:"100%"}}>
		                	  {!vendas ?
			                  	 <p className="center-align red">
			                  	 		Você ainda não fez nenhuma venda!
			                  	 </p>
		                	  	  :
					          	  vendas.map(venda => (
						              <div className="col s12 m6">
						                <div className="card hoverable">
						                  <div className="card-image">
							                  <div className="white center-align" style={{height:'300px', width: '100%'}}>
							                    <img src={window.siteurl + venda.produto.foto} alt={venda.produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
							                  </div>
											<div className="fixed-action-btn toolbar direction-top" style={{position:'relative', float:'right', bottom:'35px', right:'10px'}}>
													<a class="btn-floating btn-large halfway-fab waves-effect waves-light red"><i class="material-icons">menu</i></a>
												<ul>
											    	<li className="waves-effect waves-light"><Link to={"/chat/" + venda.venda.id}>Chat</Link></li>
											    	<li className="waves-effect waves-light"><Link to={"/venda/ver/" + venda.venda.id}>Detalhes</Link></li>
											    </ul>
											</div>				                    
						                  </div>
						                  <div className="card-content left-align">
						                    <span className="card-title" style={{color:'black'}}>
						                        <strong>{venda.produto.nome}</strong>
						                    </span>
						                    <Link to={"/usuario/perfil/" + venda.comprador.id}>{venda.comprador.nome}</Link>
						                    <h6>
						                    	<strong>{venda.venda.data}</strong>
						                    </h6>
						                    <h4>
						                      <strong>R${venda.venda.preco}</strong>
						                    </h4>
						                    {venda.venda.status == 1?
						                    <a className="btn-floating tooltipped btn-large pulse yellow darken-3" data-position="top" data-tooltip="<p><strong>Em andamento!</strong></p>"><i className="material-icons">info</i></a>
						                    :
						                    <a className="btn-floating tooltipped btn-large green darken-3" data-position="top" data-tooltip="<p><strong>Finalizada!</strong></p>"><i className="material-icons">done</i></a>
						                	}
						                  </div>
						                </div>
						              </div>
					          	  	))
				          	   }
					    </div>
					</div>
					</div>
					<button onClick={this.initToolbar} data-target="vendas" style={{marginBottom: '10px'}} className="btn waves-effect waves-light red accent-4 modal-trigger">Minhas vendas</button>
                </React.Fragment>
			)
	}
}

export default VerVendas;