import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Inicio from './Inicio';

import CadastroUsuario from './usuario/Cadastro';
import Login from './usuario/Login';
import Logout from './usuario/Logout';
import PerfilUsuario from './usuario/Perfil';
import EuUsuario from './usuario/Eu';
import EditarUsuario from './usuario/Editar';
import LojaUsuario from './usuario/Loja';

import CadastroCategoria from './categoria/Cadastro';

import CadastroProduto from './produto/Cadastro';
import VerProduto from './produto/Ver';
import Comissao from './produto/Comissao';

import CadastroVenda from './venda/Cadastro';
import VerVenda from './venda/Ver';

import CadastroAvaliacaoProduto from './avaliacao/CadastroProduto';
import CadastroAvaliacaoVendedor from './avaliacao/CadastroVendedor';

import IndexChat from './chat/IndexChat';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import 'materialize-css/dist/css/materialize.min.css';

window.siteurl = "https://apivendas.desenvolvedor.tech"
//window.siteurl = "http://192.168.1.5:5000"
ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path="/" exact={true} component={Inicio} />
            <Route path="/usuario/login" component={Login} />
            <Route path="/usuario/logout" component={Logout} />
            <Route path="/usuario/cadastro" component={CadastroUsuario} />
            <Route path="/usuario/perfil/:id_usuario" component={PerfilUsuario} />
            <Route path="/usuario/eu" component={EuUsuario} />
            <Route path="/usuario/editar" component={EditarUsuario} />
            <Route path="/usuario/loja/:id_usuario" component={LojaUsuario} />
            <Route path="/categoria/cadastro" component={CadastroCategoria} />
            <Route path="/produto/cadastro" component={CadastroProduto} />
            <Route path="/produto/ver/:id_produto" component={VerProduto} />
            <Route path="/produto/comissao/:codigo" component={Comissao} />
            <Route path="/venda/cadastro/:id_produto" component={CadastroVenda} />
            <Route path="/venda/ver/:id_venda" component={VerVenda} />
            <Route path="/avaliacao/produto/cadastro/:id_produto" component={CadastroAvaliacaoProduto} />
            <Route path="/avaliacao/vendedor/cadastro/:id_vendedor" component={CadastroAvaliacaoVendedor} />
            <Route path="/chat/:id_venda" component={IndexChat} />
        </Switch>
    </ BrowserRouter>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
