import React, { Component } from 'react';
import Navbar from '../partials/Navbar';


class Login extends Component {

  constructor() {
    super();
    this.logar = this.logar.bind(this);
  }

  componentWillMount(){
      var token = localStorage.getItem("apitoken")

      if(token){
          this.props.history.push('/')
      }

   }

   logar(event){
    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    //const data = JSON.stringify(Object.fromEntries(formData));

    var object = {};
    formData.forEach((value, key) => {object[key] = value});
    var data = JSON.stringify(object);

    fetch(window.siteurl + "/usuario/login", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.token){
            localStorage.setItem("apitoken", result.token)
            localStorage.setItem("current_user", JSON.stringify(result.usuario))
            var lastScreen = localStorage.getItem("last_screen")
            if(lastScreen){
                este.props.history.push(lastScreen)
            }
            else{
              este.props.history.push('/')
            }
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <h1>Login</h1>
            <form onSubmit={this.logar}>
              <label htmlFor="email">Email</label><br/>
              <input id="email" name="email" required size="32" type="email"/><br/>

              <label htmlFor="password">Senha</label><br/>
              <input id="password" name="password" required size="32" type="password" /><br/>

              <button className="btn waves-effect waves-light red accent-4" type="submit" id="enviar" name="action">Login
                  <i className="material-icons right">send</i>
              </button>
            </form>
        </div>
      </div>
   );
   }
}


export default Login;