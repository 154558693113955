import React, { Component } from 'react';
import Navbar from '../partials/Navbar';

class CadastroAvaliacaoVendedor extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);

    this.state = {
      vendedor: {'id':0, nome:'', foto:'/static/img/default.jpg', nota_geral:0}
    };
    
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))
      var id_vendedor = this.props.match.params.id_vendedor

      var este = this
      fetch(window.siteurl + "/usuario/perfil/"+id_vendedor)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.usuario){
              var vendedor = result.usuario
              if(!vendedor.foto){
                vendedor.foto = '/static/img/default.jpg'
              }
              this.setState({
                 vendedor: vendedor
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   cadastrar(event){
    var apitoken = localStorage.getItem("apitoken")

    const { vendedor } = this.state

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    var object = {};
    formData.forEach((value, key) => {object[key] = value});

    var data = JSON.stringify(object);

    fetch(window.siteurl + "/avaliacao/vendedor/cadastrar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/usuario/perfil/'+vendedor.id)
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {

   const { vendedor } = this.state

   return (
      <div>
        <Navbar></Navbar>
        <div className="container center-align">
            <h1>Avaliar {vendedor.nome}</h1>
            <div className="row">
                  <div className="col s12 m6">
                    <div className="card">
                      <div className="card-image">
                        <img src={window.siteurl + vendedor.foto} alt={vendedor.nome} style={{height:'300px'}}/>
                      </div>
                      <div className="card-content">
                        <span className="card-title" style={{color:'black'}}>
                            <strong>{vendedor.nome}</strong>
                        </span>
                        <h6>Nota geral: {vendedor.nota_geral}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col s12 m6">
                      <div className="valign-wrapper center-align" style={{height: '100%'}}>
                          <form onSubmit={this.cadastrar}>
                                  <input type="hidden" name="id_vendedor" value={vendedor.id} />
                                  <p style={{marginTop:'10px'}}>Comentário:</p>
                                  <textarea id="comentario" name="comentario" className="materialize-textarea"></textarea>
                                  <p style={{marginTop:'10px'}}>Avaliação:</p>
                                  <label>
                                    <input name="nota" type="radio" value="1" required />
                                    <span>😡</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="2" />
                                    <span>😑</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="3" />
                                    <span>😶</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="4" />
                                    <span>🙂</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="5" />
                                    <span>😀</span>
                                  </label>
                                  <br/><br/>
                                  <button style={{marginTop:'10px'}} className="btn-large waves-effect waves-light red accent-4" type="submit" name="action">
                                          Concluir
                                          <i class="material-icons right">check</i>
                                  </button>
                          </form>
                      </div>
                  </div>
            </div>
        </div>
      </div>
   )

   }
}


export default CadastroAvaliacaoVendedor;