import React from 'react';

import M from "materialize-css";

class Dropdown extends React.Component{

    constructor() {
      super();
      this.getLink = this.getLink.bind(this);
      this.deletar = this.deletar.bind(this)
      this.state = {linkcomissao: ''};
    }

    componentDidMount(){
      var elems = document.querySelectorAll('.dropdown-trigger');
      var instances = M.Dropdown.init(elems, {'closeOnClick':false});
    }

    getLink(){
      var id_produto = this.props.produto_id
      var apitoken = localStorage.getItem("apitoken")
      var websiteroot = window.location.protocol + '//' + window.location.host;

      fetch(window.siteurl + "/produto/comissao/"+id_produto, 
        {
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
        })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.mensagem){
              this.setState({
                 linkcomissao: websiteroot+ '/produto/comissao/' + result.mensagem
              })
            }
            else{
               alert(result.msg)
            }
          },
          (error) => {
            alert(error)
          }
        )
    }

    deletar(){
      var este = this.props.este
      var id_produto = this.props.produto_id
      var apitoken = localStorage.getItem("apitoken")
      var websiteroot = window.location.protocol + '//' + window.location.host;

      fetch(window.siteurl + "/produto/deletar/"+id_produto,
        {
          method: 'DELETE',
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
        })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.sucesso){
              alert('Produto deletado com sucesso!')
              este.props.history.push('/')
            }
            else{
               alert(result.msg)
            }
          },
          (error) => {
            alert(error)
          }
        )
    }

    render(){
       var usuario = localStorage.getItem("current_user")
       var id_anunciante = this.props.id_anunciante

       if(usuario){
            usuario = JSON.parse(usuario)
            if(usuario.admin || usuario.id === id_anunciante){
                return (
                    <React.Fragment>
                    <span>
                      <h2 className='dropdown-trigger' href='#' data-target='dropdown1'>
                        <i className='material-icons right'>more_vert</i>
                      </h2>
                    </span>
                    <ul id='dropdown1' className='dropdown-content red darken-3'>
                      <li><input id="linkcomissao" type="text" readOnly value={this.state.linkcomissao} placeholder="Link de comissão"/></li>
                      <li><button style={{width:'100%'}} onClick={this.getLink} className="waves-effect waves-light btn-large red accent-4">Gerar link</button></li>
                      <li><button style={{width:'100%'}} onClick={this.deletar} className="waves-effect waves-light btn-large red accent-4">Deletar produto</button></li>
                    </ul>
                    </React.Fragment>
                  )
            }
            else{
                return (
                    <React.Fragment>
                    <span>
                      <h2 className='dropdown-trigger' href='#' data-target='dropdown1'>
                        <i className='material-icons right'>more_vert</i>
                      </h2>
                    </span>
                    <ul id='dropdown1' className='dropdown-content red darken-3'>
                      <li><input id="linkcomissao" type="text" readOnly value={this.state.linkcomissao} placeholder="Link de comissão"/></li>
                      <li><button style={{width:'100%'}} onClick={this.getLink} className="waves-effect waves-light btn-large red accent-4">Gerar link</button></li>
                    </ul>
                    </React.Fragment>
                  )
            }
       }
       else{
            return null
       }
    }
}

export default Dropdown;