import React from 'react';
import { Link } from 'react-router-dom';

class Botaoavaliar extends React.Component{
	constructor() {
	  super();
	  this.state = {aceito: null};
	}

	componentWillMount(){
	  var token = localStorage.getItem("apitoken")
	  if (token){
	  	  var este = this
	      var id_vendedor = this.props.id_vendedor

	      fetch(window.siteurl + "/avaliacao/vendedor/autorizado/"+id_vendedor,
	        {
	          headers: {
	            'Authorization': 'Bearer '+token
	          },
	        })
	        .then(res => res.json())
	        .then(
	          (result) => {
	            if(result.sucesso){
	            	este.setState({
	            		aceito: 1
	            	})
	            }
	          },
	          (error) => {
	          }
	        )
	  }
	}

	render(){
		 const { aceito } = this.state
		 if(aceito){
        	return (
            	  <React.Fragment>
	                  <p className="center-align">
	                  <Link to={"/avaliacao/vendedor/cadastro/" + this.props.id_vendedor} className="waves-effect waves-light btn-large red accent-4">
	                    Avaliar vendedor<i class="material-icons right">rate_review</i>
	                  </Link>
	                  </p>
                  </React.Fragment>
        		)
		 }
		 else{
		 	return null
		 }
	}
 }

class Avaliacoes extends React.Component{
	constructor() {
	  super();
	  this.state = {avaliacoes: null};
	}

	componentDidMount(){
	    fetch(window.siteurl + "/avaliacao/vendedor/listar/" + this.props.id_vendedor)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          if(result.avaliacoes){
		            this.setState({
		               avaliacoes: result.avaliacoes
		            })
	          }
	        },
	        (error) => {
	          alert(error)
	        }
	      )
	}

	render(){
		const { avaliacoes } = this.state
		return(
                <div className="col s12 m6 center-align">
                    <h3>Avaliações</h3>
                    <div style={{maxHeight:"350px", overflow:"auto"}}>
                    	  {!avaliacoes ?
		                  	 <p className="center-align red">
		                  	 		No momento não há avaliações cadastradas neste vendedor
		                  	 </p>
                    	  	  :
				          	  avaliacoes.map(avaliacao => (
		                        <div className="card red lighten-5 hoverable">
		                          <div className="card-content">
		                            <span className="card-title">Nota: {avaliacao.nota}</span>
		                            <p>{avaliacao.comentario}</p>
		                          </div>
		                        </div>
				          	  	))
			          	   }
                    </div>
                    <p>
					<Botaoavaliar id_vendedor={this.props.id_vendedor} />
                    </p>
                </div>
			)
	}
}

export default Avaliacoes;