import React from 'react';
import { Link } from 'react-router-dom';

class Botaoavaliar extends React.Component{
	constructor() {
	  super();
	  this.state = {aceito: null};
	}

	componentWillMount(){
	  var token = localStorage.getItem("apitoken")
	  if (token){
	  	  var este = this
	      var id_produto = this.props.id_produto

	      fetch(window.siteurl + "/avaliacao/produto/autorizado/"+id_produto,
	        {
	          headers: {
	            'Authorization': 'Bearer '+token
	          },
	        })
	        .then(res => res.json())
	        .then(
	          (result) => {
	            if(result.sucesso){
	            	este.setState({
	            		aceito: 1
	            	})
	            }
	          },
	          (error) => {
	          }
	        )
	  }
	}

	render(){
		 const { aceito } = this.state
		 if(aceito){
        	return (
            	  <React.Fragment>
	                  <p className="center-align">
	                  <Link to={"/avaliacao/produto/cadastro/" + this.props.id_produto} className="waves-effect waves-light btn-large red accent-4">
	                    Avaliar produto<i class="material-icons right">rate_review</i>
	                  </Link>
	                  </p>
                  </React.Fragment>
        		)
		 }
		 else{
		 	return null
		 }
	}
 }

class Avaliacoes extends React.Component{
	constructor() {
	  super();
	  this.state = {avaliacoes: null};
	}

	componentDidMount(){
	    fetch(window.siteurl + "/avaliacao/produto/listar/" + this.props.id_produto)
	      .then(res => res.json())
	      .then(
	        (result) => {
	          if(result.avaliacoes){
		            this.setState({
		               avaliacoes: result.avaliacoes
		            })
	          }
	        },
	        (error) => {
	          alert(error)
	        }
	      )
	}

	render(){
		const { avaliacoes } = this.state
		return(
                <div className="row">
                  <h1 className="center-align">Avaliações do produto</h1>
                  <Botaoavaliar id_produto={this.props.id_produto} />
                  {!avaliacoes ?
                  	 <p className="center-align red">
                  	 		No momento não há avaliações cadastradas neste produto
                  	 </p>
                  	 :
		          	  avaliacoes.map(avaliacao => (
		                  <div className="col s12 m6">
		                        <div className="card red darken-3">
		                          <div className="card-content white-text">
		                            <span className="card-title">Nota: {avaliacao.nota}</span>
		                            <p>{avaliacao.comentario}</p>
		                          </div>
		                        </div>
		                  </div>
		          	  	))
              	  }
                </div>
			)
	}
}

export default Avaliacoes;