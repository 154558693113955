import React, { Component } from 'react';
import Navbar from '../partials/Navbar';

class CadastroAvaliacaoProduto extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);

    this.state = {
      produto: {comissao:0, preco:0, nome:'', descricao:'', 
        id_categoria:0, id_vendedor:0, tags:'', foto:''}
    };
    
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))
      var id_produto = this.props.match.params.id_produto

      var este = this
      fetch(window.siteurl + "/produto/ver/"+id_produto)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.produto){
              this.setState({
                 produto: result.produto
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   cadastrar(event){
    var apitoken = localStorage.getItem("apitoken")

    const { produto } = this.state

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    var object = {};
    formData.forEach((value, key) => {object[key] = value});

    var data = JSON.stringify(object);

    fetch(window.siteurl + "/avaliacao/produto/cadastrar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/produto/ver/'+produto.id)
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {

   var produto = this.state.produto

   return (
      <div>
        <Navbar></Navbar>
        <div className="container center-align">
            <h1>Avaliar {produto.nome}</h1>
            <div className="row">
                  <div className="col s12 m6">
                    <div className="card">
                      <div className="card-image">
                        <div className="white center-align" style={{height:'400px', width: '100%'}}>
                          <img src={window.siteurl + produto.foto} alt={produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
                        </div>
                      </div>
                      <div className="card-content">
                        <span className="card-title" style={{color:'black'}}>
                            <strong>{produto.nome}</strong>
                        </span>
                        <h4>
                          <strong>R${produto.preco}</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col s12 m6">
                      <div className="valign-wrapper center-align" style={{height: '100%'}}>
                          <form onSubmit={this.cadastrar}>
                                  <input type="hidden" name="id_produto" value={produto.id} />
                                  <p style={{marginTop:'10px'}}>Comentário:</p>
                                  <textarea id="comentario" name="comentario" className="materialize-textarea"></textarea>
                                  <p style={{marginTop:'10px'}}>Avaliação:</p>
                                  <label>
                                    <input name="nota" type="radio" value="1" required />
                                    <span>😡</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="2" />
                                    <span>😑</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="3" />
                                    <span>😶</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="4" />
                                    <span>🙂</span>
                                  </label>
                                  <label>
                                    <input name="nota" type="radio" value="5" />
                                    <span>😀</span>
                                  </label>
                                  <br/><br/>
                                  <button style={{marginTop:'10px'}} className="btn-large waves-effect waves-light red accent-4" type="submit" name="action">
                                          Concluir
                                          <i class="material-icons right">check</i>
                                  </button>
                          </form>
                      </div>
                  </div>
            </div>
        </div>
      </div>
   )

   }
}


export default CadastroAvaliacaoProduto;