import React, { Component } from 'react';
import Navbar from './partials/Navbar';
import { Link } from 'react-router-dom';

class Inicio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      produtos: []
    };
  }


  componentDidMount() {
    fetch(window.siteurl + "/produto/listar")
      .then(res => res.json())
      .then(
        (result) => {
          if(result.produtos){
            //alert(JSON.stringify(result.produtos))
            this.setState({
               produtos: result.produtos
            })
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )
  }
   render() {
   const { produtos } = this.state;

   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
          <h1>Bem-vindo ao FastMarket!</h1>
          <h5>Alguns produtos disponíveis:</h5>
          <div className="row" style={{marginTop:'30px'}}>
            {produtos.map(produto => (
              <div key={produto.id} className="col s12 m4">
                <div className="card hoverable">
                  <div className="card-image">
                    <div className="white center-align" style={{height:'250px', width: '100%'}}>
                      <img src={window.siteurl + produto.foto} alt={produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
                    </div>
                  </div>
                  <div className="card-content">
                    <span className="card-title" style={{fontSize: '15px', color:'black'}}>
                        <strong>{produto.nome}</strong>
                    </span>
                    <p><strong>Comissão: {produto.comissao}%</strong></p>
                    <h6>
                      <strong>R${produto.preco}</strong>
                    </h6>
                  </div>
                  <div className="card-action">
                      <Link to={"/produto/ver/" + produto.id}>Mais detalhes</Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
   );
   }
}

export default Inicio;
