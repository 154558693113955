import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import { Link } from 'react-router-dom';

class IndexChat extends Component {

  constructor() {
    super();
    this.enviar = this.enviar.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.state = {
      mensagens: [],
      texto: '',
      venda: {id: 0, nome: ''},
      produto: {nome: ''}
    }
    
  }

  componentDidMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario){
          this.props.history.push('/usuario/login')
      }

      this.getVenda();
      this.procurarMensagens();
   }

   procurarMensagens(){
      var apitoken = localStorage.getItem("apitoken")
      var id_venda = this.props.match.params.id_venda
      var este = this
      fetch(window.siteurl + "/venda/chat/"+id_venda, {
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
         })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.mensagens){
              this.setState({
                 mensagens: result.mensagens
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   enviar(event){
    var apitoken = localStorage.getItem("apitoken")

    const este = this;

    var object = {}
    object["id_venda"] = this.props.match.params.id_venda
    object["texto"] = this.state.texto

    var data = JSON.stringify(object);

    fetch(window.siteurl + "/venda/chat/enviar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
             este.procurarMensagens()
             this.setState({texto: ''})
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

  handleChange(event) {
    this.setState({texto: event.target.value});
  }

  getVenda(){
      var apitoken = localStorage.getItem("apitoken")
      var id_venda = this.props.match.params.id_venda
      var este = this
      fetch(window.siteurl + "/venda/listar/id/"+id_venda, {
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
         })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.venda){
              this.setState({
                 venda: result.venda,
                 produto: result.produto
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
  }
  render() {

   var mensagens = this.state.mensagens
   const { venda, produto } = this.state
   var id_usuario = JSON.parse(localStorage.getItem("current_user")).id

   var msgEle = {
        clear: 'both',
        display:'block',
        float:'left',
        minWidth:'20%',
        maxWidth:'60%',
        borderRadius:'10px',
        marginBottom:'10px',
        marginLeft:'10%',
        textAlign:'left',
        padding:'10px',
   }

   var msgEu = {
        clear: 'both',
        display:'block',
        float:'right',
        minWidth:'20%',
        maxWidth:'60%',
        borderRadius:'10px',
        marginBottom:'10px',
        marginRight:'10%',
        textAlign:'left',
        padding:'10px',
   }

   return (
      <div>
        <Navbar></Navbar>
        <div className="container center-align">
            <h1><Link to={"/venda/ver/" + venda.id}>{produto.nome}</Link></h1>
            <div style={{width:'80%', margin:'auto'}}>
                <div className="red lighten-5" style={{maxHeight:'500px', overflow:'auto', borderRadius:'20px'}}>
                      {mensagens.map(mensagem => (
                              (mensagem.id_usuario == id_usuario) ?
                                <React.Fragment>
                                <p style={msgEu} className="red darken-4">
                                    <span className="white-text">{mensagem.data_envio + ": "}</span>{mensagem.texto}
                                </p>
                                </React.Fragment>
                              :
                                <React.Fragment>
                                <p style={msgEle} className="black white-text">
                                    <span className="green-text">{mensagem.data_envio + ": "}</span>{mensagem.texto}
                                </p>
                                </React.Fragment>
                        ))
                      }
                </div>
                <div style={{marginTop:'10px'}} width="100%">
                    <div class="row">
                        <div class="col s8">
                          <input type="text" value={this.state.texto} onChange={this.handleChange}></input>
                        </div>
                        <div class="col s4">
                          <button id="send" onClick={this.enviar} class="btn waves-effect waves-light red accent-4" type="submit">Enviar
                                  <i class="material-icons right">send</i>
                              </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
   )

   }
}


export default IndexChat;