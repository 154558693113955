import React, { Component } from 'react';

class Logout extends Component{

	componentWillMount(){
	  localStorage.removeItem("apitoken")
      localStorage.removeItem("current_user")

      this.props.history.push("/usuario/login")
	}

	render(){
		return(<p></p>)
	}
}

export default Logout;