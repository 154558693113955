import React, { Component } from 'react';
import Navbar from '../partials/Navbar';

class EditarUsuario extends Component {

  constructor() {
    super();
    this.state = {
      usuario: {id:0, nome:'', foto:'/static/img/default.jpg', 'nota_geral':0,
              email:'', cpf:'', celular:'', endereco:'', info_bancaria:'',
              arrecadamento:0
              }
    };
    this.editar = this.editar.bind(this);
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario){
          this.props.history.push('/')
      }

      else{
          var apitoken = localStorage.getItem("apitoken")
          var este = this
          fetch(window.siteurl + "/usuario/eu", {
              headers: {
                'Authorization': 'Bearer '+apitoken
              },
            })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.usuario){
                  var usuario = result.usuario
                  if(!usuario.foto){
                    usuario.foto = '/static/img/default.jpg'
                  }
                  this.setState({
                     usuario: result.usuario
                  })
                }
                else{
                  este.props.history.push('/')
                }
              },
              (error) => {
                alert(error)
              }
            )
      }
   }

   editar(event){
    event.preventDefault();

    var apitoken = localStorage.getItem("apitoken")

    var este = this
    
    var formData = new FormData(event.target);

    fetch(window.siteurl + "/usuario/editar", {
        method: 'PUT',
        headers: {
          'Authorization': 'Bearer '+apitoken
        },
        body: formData,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/usuario/eu')
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   handleChange(event, atributo){
      var valor = event.target.value
      var newusuario = this.state.usuario
      newusuario[atributo] = valor
      this.setState({usuario: newusuario})
   }

   render() {
   const { usuario } = this.state
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <h1>Editar</h1>
            <p><strong>*Campos em branco não serão alterados</strong></p>
            <form onSubmit={this.editar} encType="multipart/form-data">
              <label htmlFor="email">Email</label><br/>
              <input id="email" name="email" size="32" type="email" value={usuario.email} onChange={(e) => this.handleChange(e, "email")} /><br/>

              <label htmlFor="file">Foto</label><br/>
              <input id="foto" name="foto" size="32" type="file" accept="image/png, image/jpeg, image/gif" /><br/>

              <label htmlFor="nome">Celular</label><br/>
              <input id="celular" name="celular" size="32" type="text" value={usuario.celular} onChange={(e) => this.handleChange(e, "celular")} /><br/>

              <label htmlFor="endereco">Endereço</label><br/>
              <input id="endereco" name="endereco" size="32" type="text" value={usuario.endereco} onChange={(e) => this.handleChange(e, "endereco")} /><br/>

              <label htmlFor="info_bancaria">Email da conta Paypal</label><br/>
              <input id="info_bancaria" name="info_bancaria" size="32" type="text" value={usuario.info_bancaria} onChange={(e) => this.handleChange(e, "info_bancaria")} /><br/>

              <label htmlFor="password">Senha</label><br/>
              <input id="password" name="password" size="32" type="password"/><br/>

              <label htmlFor="password2">Confirmar senha</label><br/>
              <input id="password2" name="password2" size="32" type="password" /><br/>
              <button className="btn waves-effect waves-light red accent-4" id="submit" type="submit">
                  Editar<i className="material-icons right">send</i>
              </button>
            </form>
        </div>
      </div>
   );
   }
}

export default EditarUsuario;