import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import { Link } from 'react-router-dom';

import M from "materialize-css";

class VerVenda extends Component {

  constructor() {
    super();
    this.finalizar = this.finalizar.bind(this);
    this.state = {
      venda: {id:0, status:'', endereco:'', metodo_pag:0,
              preco:'', data:''
              },
      produto: {id:0, nome:'', descricao:'', foto:'/static/img/default.jpg', comissao:0},
      vendedor: {id:0, nome:'', foto:''},
      cliente: {id:0, nome:'', foto:''}
    };
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario){
          this.props.history.push('/')
      }


      var id_venda = this.props.match.params.id_venda
      var apitoken = localStorage.getItem("apitoken")
      var este = this
      fetch(window.siteurl + "/venda/listar/id/"+id_venda, {
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
        })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.venda){
              var venda = result.venda
              if(venda.status == '1'){
                    venda.status_texto = "Em andamento"
              }
              if(venda.status == '2'){
                   venda.status_texto = "Finalizado!"
              }

              if(venda.metodo_pag == '1'){
                    venda.metodo_pag = "Paypal"
              }
              this.setState({
                 venda: result.venda,
                 produto: result.produto,
                 cliente: result.cliente,
                 vendedor: result.vendedor
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   finalizar(){
      var id_venda = this.props.match.params.id_venda
      var apitoken = localStorage.getItem("apitoken")
      var este = this
      fetch(window.siteurl + "/venda/finalizar/"+id_venda, {
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
          method: 'PUT',
        })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.sucesso){
              alert('Sua compra foi finalizada!')
              window.location.reload(true);
            }
            else{
              alert(result.msg)
            }
          },
          (error) => {
            alert(error)
          }
        )      
   }

   render() {
   const { venda, produto, cliente, vendedor } = this.state
   var usuario = JSON.parse(localStorage.getItem("current_user"))
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <div className="row red darken-3" style={{borderRadius:'3px', marginTop:'50px'}}>
                <div className="col s12 m6">
                  <div className="card">
                    <div className="card-image">
                    <div className="white center-align" style={{height:'350px', width: '100%'}}>
                      <img src={window.siteurl + produto.foto} alt={produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
                    </div>
                    </div>
                    <div class="card-content">
                      <h1 class="card-title"><Link to={"/produto/ver/" + produto.id}>{produto.nome}</Link></h1>
                      <h3>R${venda.preco}</h3>
                    </div>
                  </div>
                </div>
                <div className="col s12 m6">
                    <h3 className="center-align">Informações da negociação</h3>

                    <ul className="collapsible">
                      <li>
                        <div className="collapsible-header">Status<i class="material-icons">details</i></div>
                        <div className="collapsible-body white">
                        <p>{venda.status_texto}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Endereço para entrega<i class="material-icons">home</i></div>
                        <div className="collapsible-body white">
                        <p>{venda.endereco}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Método de pagamento<i class="material-icons">credit_card</i></div>
                        <div className="collapsible-body white">
                        <p>{venda.metodo_pag}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Data de início<i class="material-icons">date_range</i></div>
                        <div className="collapsible-body white">
                        <p>{venda.data}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Cliente<i class="material-icons">person</i></div>
                        <div className="collapsible-body white">
                        <Link to={"/usuario/perfil/" + cliente.id}>{cliente.nome}</Link>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Vendedor<i class="material-icons">person</i></div>
                        <div className="collapsible-body white">
                        <Link to={"/usuario/perfil/" + vendedor.id}>{vendedor.nome}</Link>
                        </div>
                      </li>
                    </ul>
                    <Link to={"/chat/" + venda.id} style={{marginRight:'5px', marginBottom:'10px'}} className="btn waves-effect waves-light red accent-4">
                            Ver chat
                            <i class="material-icons right">chat</i>
                    </Link>
                    <Link to="#" style={{marginBottom:'10px'}} className="btn waves-effect waves-light red accent-4">
                            Abrir reclamação
                            <i class="material-icons right">call</i>
                    </Link>
                    {(venda.status != '2') && (usuario.id == cliente.id) ?
                    <button data-target="finalizar" style={{marginTop:'5px', marginBottom:'10px'}} className="btn waves-effect waves-light red accent-4 btn modal-trigger">
                            Recebi o produto (Finalizar compra)
                            <i class="material-icons right">done</i>
                    </button>
                    :
                    null
                    }
                </div>
                <div id="finalizar" class="modal">
                  <div class="modal-content">
                    <h2>Deseja confirmar que recebeu a encomenda e finalizar a compra?</h2>
                  </div>
                  <div class="modal-footer">
                    <a href="#!" style={{marginRight:'10px'}} class="modal-close white-text waves-effect red accent-4 btn-flat">Não</a>
                    <a href="#!" onClick={this.finalizar} class="white-text waves-effect green accent-4 btn-flat">Sim, confirmo que recebi a encomenda</a>
                  </div>
                </div>
            </div>
        </div>
      </div>
   );
   }
}

export default VerVenda;