import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import { Link } from 'react-router-dom';

class LojaUsuario extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usuario: {id:0, nome:'', foto:''},
      produtos: null
    };
  }

  componentWillMount(){
    var id_usuario = this.props.match.params.id_usuario

    fetch(window.siteurl + "/usuario/perfil/" + id_usuario)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.usuario){
            this.setState({
               usuario: result.usuario
            })
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )
  }
  componentDidMount() {
    var id_usuario = this.props.match.params.id_usuario
    fetch(window.siteurl + "/usuario/produtos/" + id_usuario)
      .then(res => res.json())
      .then(
        (result) => {
          if(result.produtos){
            if(result.produtos.length > 0){
              this.setState({
                 produtos: result.produtos
              })
            }
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )
  }
   render() {
   const { produtos, usuario } = this.state;

   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
          <h1 className="center-align">Loja de {usuario.nome}</h1>
          <div className="row" style={{marginTop:'30px'}}>
            {!produtos ?
               <h3 className="center-align red">
                  Ainda não há produtos nesta loja!
               </h3>
              :
              produtos.map(produto => (
                <div key={produto.id} className="col s12 m6">
                  <div className="card hoverable">
                    <div className="card-image">
                      <div className="white center-align" style={{height:'400px', width: '100%'}}>
                        <img src={window.siteurl + produto.foto} alt={produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
                      </div>
                    </div>
                    <div className="card-content">
                      <span className="card-title" style={{color:'black'}}>
                          <strong>{produto.nome}</strong>
                      </span>
                      <h4>
                        <strong>R${produto.preco}</strong>
                      </h4>
                    </div>
                    <div className="card-action">
                        <Link to={"/produto/ver/" + produto.id}>Mais detalhes</Link>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
   );
   }
}

export default LojaUsuario;
