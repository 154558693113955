import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Navbar from '../partials/Navbar';

import Dropdown from './partials/Dropdown';
import Galeria from './partials/Galeria';
import Avaliacoes from './partials/Avaliacoes';

import M from "materialize-css";

class VerProduto extends Component {

  constructor() {
    super();
    this.state = {
      produto: {id:0, comissao:0, preco:0, nome:'', descricao:'', 
        id_categoria:0, id_vendedor:0, tags:'', foto:''},
      vendedor: {nome:'', foto:''}
    };
  }

   componentWillMount(){
      var este = this
      var id_produto = this.props.match.params.id_produto
      fetch(window.siteurl + "/produto/ver/"+id_produto)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.produto){
              this.setState({
                 produto: result.produto,
                 vendedor: result.vendedor
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   render() {
    const { produto, vendedor } = this.state
    const siteurl = window.siteurl
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <div className="col s12 m12">
                  <div className="row red darken-3" style={{borderRadius:'3px', marginTop:'50px'}}>
                      <div className="card-image col s12 m8 center-align">
                        <div style={{borderRadius: '10px', backgroundColor: 'white', marginTop:'10px', marginBottom:'10px', width:'100%', height:'325px'}} >
                          <img style={{borderRadius: '10px', maxWidth:"100%", width:'auto'}} alt={produto.nome} src={siteurl + produto.foto} height="100%"/>
                        </div>
                        <Galeria este={this} produto={produto} />
                      </div>
                      <div className="col s12 m4" style={{marginBottom:'10px'}}>
                        <div className="card-content center-align">
                            <Dropdown este={this} id_anunciante={produto.id_vendedor} produto_id={produto.id} />
                            <h4>{produto.nome}</h4>
                            <h3><strong>R${produto.preco}</strong></h3>

                            <Link to={"/venda/cadastro/" + produto.id} className="waves-effect waves-light btn-large red accent-4">
                              Comprar<i class="material-icons right">shop</i>
                            </Link>
                            <h5>Comissão: {produto.comissao}%</h5>
                            <h5>Vendedor: <Link to={"/usuario/perfil/" + vendedor.id}>{vendedor.nome}</Link></h5>
                        </div>
                      </div>
                  </div>
                <div className="row">
                  <div className="col s12 m12">
                        <div className="card red darken-3">
                          <div className="card-content white-text">
                            <span className="card-title">Sobre o produto</span>
                            <p>{produto.descricao}</p>
                          </div>
                        </div>
                  </div>
                </div>
                <Avaliacoes id_produto={this.props.match.params.id_produto}></Avaliacoes>
              </div>
        </div>
     </div>
   );
   }
}


export default VerProduto;