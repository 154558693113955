import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import { Link } from 'react-router-dom';

import Avaliacoes from './partials/Avaliacoes';


class PerfilUsuario extends Component {

  constructor() {
    super();
    this.state = {
      usuario: {'id':0, nome:'', foto:'/static/img/default.jpg', 'nota_geral':0}
    };
  }

  componentWillMount(){
      var este = this
      var id_usuario = this.props.match.params.id_usuario
      fetch(window.siteurl + "/usuario/perfil/"+id_usuario)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.usuario){
              var usuario = result.usuario
              if(!usuario.foto){
                usuario.foto = '/static/img/default.jpg'
              }
              this.setState({
                 usuario: result.usuario
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   render() {
   const { usuario } = this.state
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <div className="row red darken-3" style={{borderRadius:'3px', marginTop:'50px'}}>
                <div className="col s12 m6">
                  <div className="card">
                    <div className="card-image">
                      <img src={window.siteurl + usuario.foto} height="350px" width="100%" />
                    </div>
                    <div class="card-content">
                      <h1 class="card-title">{usuario.nome}</h1>
                      <h6>Nota geral: {usuario.nota_geral}</h6>
                      <Link className="btn-large waves-effect waves-light red accent-4" to={"/usuario/loja/" + usuario.id}>Ver loja</Link>
                    </div>
                  </div>
                </div>
                <Avaliacoes id_vendedor={this.props.match.params.id_usuario} />
            </div>
        </div>
      </div>
   );
   }
}


export default PerfilUsuario;