import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import VerCompras from './partials/VerCompras';
import VerVendas from './partials/VerVendas';
import { Link } from 'react-router-dom';

import M from "materialize-css";

class EuUsuario extends Component {

  constructor() {
    super();
    this.sacar = this.sacar.bind(this);
    this.state = {
      usuario: {id:0, nome:'', foto:'/static/img/default.jpg', 'nota_geral':0,
              email:'', cpf:'', celular:'', endereco:'', info_bancaria:'',
              arrecadamento:0
              },
      carregando: ''
    };
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario){
          this.props.history.push('/')
      }

      else{
          var apitoken = localStorage.getItem("apitoken")
          var este = this
          fetch(window.siteurl + "/usuario/eu", {
              headers: {
                'Authorization': 'Bearer '+apitoken
              },
            })
            .then(res => res.json())
            .then(
              (result) => {
                if(result.usuario){
                  var usuario = result.usuario
                  if(!usuario.foto){
                    usuario.foto = '/static/img/default.jpg'
                  }
                  this.setState({
                     usuario: result.usuario
                  })
                }
                else{
                  este.props.history.push('/')
                }
              },
              (error) => {
                alert(error)
              }
            )
      }
   }

   sacar(event){
    var apitoken = localStorage.getItem("apitoken")

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    var object = {};
    formData.forEach((value, key) => {object[key] = value});
    var data = JSON.stringify(object);

    this.setState({carregando:'Carregando...'})
    fetch(window.siteurl + "/venda/sacar", {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.setState({carregando:''})
            window.location.reload(true)
          }
          else{
            este.setState({carregando:''})
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )
   }

   render() {
   const { usuario } = this.state
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <div className="row red darken-3" style={{borderRadius:'3px', marginTop:'50px'}}>
                <div className="col s12 m6">
                  <div className="card">
                    <div className="card-image">
                      <img src={window.siteurl + usuario.foto} height="350px" width="100%" />
                    </div>
                    <div class="card-content">
                      <h1 class="card-title">{usuario.nome}</h1>
                      <div className="center-align">
                      <VerCompras />
                      <VerVendas />
                      <br/>
                      <Link style={{marginRight:'10px', marginTop:'10px'}} className="btn waves-effect waves-light red accent-4" to={"/usuario/loja/" + usuario.id}>Minha loja</Link>
                      <Link style={{marginTop:'10px'}} className="btn waves-effect waves-light red accent-4" to={"/usuario/perfil/" + usuario.id}>Perfil público</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col s12 m6">
                    <h3 className="center-align">Informações pessoais</h3>

                    <ul className="collapsible">
                      <li>
                        <div className="collapsible-header">Email<i class="material-icons">email</i></div>
                        <div className="collapsible-body white">
                        <p>{usuario.email}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">CPF<i class="material-icons">person</i></div>
                        <div className="collapsible-body white">
                        <p>{usuario.cpf}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Celular<i class="material-icons">smartphone</i></div>
                        <div className="collapsible-body white">
                        <p>{usuario.celular}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Endereço<i class="material-icons">home</i></div>
                        <div className="collapsible-body white">
                        <p>{usuario.endereco}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Email da conta Paypal<i class="material-icons">attach_money</i></div>
                        <div className="collapsible-body white">
                        <p>{usuario.info_bancaria}</p>
                        </div>
                      </li>
                      <li>
                        <div class="collapsible-header">Saldo<i class="material-icons">monetization_on</i></div>
                        <div className="collapsible-body white">
                          <h4>R${usuario.arrecadamento}</h4>
                          <button data-target="sacar" style={{marginTop:'5px', marginBottom:'10px'}} className="btn waves-effect waves-light red accent-4 btn modal-trigger">
                                  Sacar dinheiro
                                  <i class="material-icons right">monetization_on</i>
                          </button>
                        </div>
                        <div id="sacar" class="modal">
                          <div class="modal-content">
                            <h6>Informe o seu email da conta Paypal e a quantia a qual você deseja sacar</h6>
                            <form method="post" onSubmit={this.sacar}>
                                    <label htmlFor="email">Email</label><br/>
                                    <input id="email" name="email" required size="32" type="email" /><br/>

                                    <label htmlFor="quantia">Quantia</label><br/>
                                    <input id="quantia" name="quantia" required size="32" type="number" /><br/>

                                    <button className="btn waves-effect waves-light red accent-4" type="submit" id="enviar" name="action">
                                            Sacar
                                            <i className="material-icons right">send</i>
                                    </button>
                                    <h1 class="center-align">{this.state.carregando}</h1>
                            </form>
                          </div>
                          <div class="modal-footer">
                            <a href="#!" class="modal-close white-text waves-effect red accent-4 btn-flat">Fechar</a>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <Link to="/usuario/editar" style={{marginBottom: '10px'}} className="btn-large waves-effect waves-light red accent-4">
                            Editar informações
                            <i class="material-icons right">shop</i>
                    </Link>
                </div>
            </div>
        </div>
      </div>
   );
   }
}

export default EuUsuario;