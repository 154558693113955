import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import M from "materialize-css";


 function Itensnav(){
    var token = localStorage.getItem("apitoken")
    var usuario = localStorage.getItem("current_user")


    if(token){
        usuario = JSON.parse(usuario)
        if(usuario.admin){
            return (
              <React.Fragment>
              <li><Link to="/usuario/eu">Meu perfil</Link></li>
              <li><Link to="/categoria/cadastro">Cadastrar categoria</Link></li>
              <li><Link to="/produto/cadastro">Cadastrar produto</Link></li>
              <li><a href="/usuario/logout">Logout</a></li>
              </React.Fragment>
            )
        }
        else{      
            return (
              <React.Fragment>
              <li><Link to="/usuario/eu">Meu perfil</Link></li>
              <li><Link to="/produto/cadastro">Cadastrar produto</Link></li>
              <li><a href="/usuario/logout">Logout</a></li>
              </React.Fragment>
            )
        }
    }
    else{
        return (
            <React.Fragment>
            <li><Link to="/usuario/login">Login</Link></li>
            <li><Link to="/usuario/cadastro">Cadastrar</Link></li>
            </React.Fragment> 
        )
    }
 }

class Navbar extends Component {

    componentDidMount() {
        M.AutoInit();

        var body = document.getElementsByTagName('body')

        body[0].style.overflow = 'visible'
    }

   render() {
   return (
          <React.Fragment>
            <div className="navbar-fixed">
              <nav className="black">
                    <div className="nav-wrapper container">
                            <a href="#" className="brand-logo right" style={{marginTop:'8px'}}>
                              <img width="150px" height="40px "src="/logo.png" alt="logo fask market" />
                            </a>
                            <a href="#" data-target="mobile-menu" className="sidenav-trigger">
                              <i className="material-icons">menu</i>
                            </a>
                            <ul className="left hide-on-med-and-down">
                                    <li><Link to="/">Inicio</Link></li>
                                    <Itensnav />
                             </ul>
                    </div>
            </nav>
          </div>
         <ul className="sidenav" id="mobile-menu">
                <li><Link to="/">Inicio</Link></li>
                <Itensnav />
         </ul>
       </React.Fragment>
   );
   }
}
export default Navbar;
