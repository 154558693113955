import React from 'react';
import ReactDOM from 'react-dom';
import scriptLoader from 'react-async-script-loader';

class PaypalButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        showButton: false,
      };
      window.React = React;
      window.ReactDOM = ReactDOM;
    }
    componentDidMount() {
      const {
        isScriptLoaded,
        isScriptLoadSucceed
      } = this.props;
      if (isScriptLoaded && isScriptLoadSucceed) {
        this.setState({ showButton: true });
      }
    }
    componentWillReceiveProps(nextProps) {
      const {
        isScriptLoaded,
        isScriptLoadSucceed,
      } = nextProps;
      const isLoadedButWasntLoadedBefore =
        !this.state.showButton &&
        !this.props.isScriptLoaded &&
        isScriptLoaded;
      if (isLoadedButWasntLoadedBefore) {
        if (isScriptLoadSucceed) {
          this.setState({ showButton: true });
        }
      }
    }
    render() {
      const paypal = window.PAYPAL
      
      const {
        total,
        currency,
        env,
        commit,
        client,
        onSuccess,
        onError,
        onCancel,
      } = this.props;
      const {
        showButton,
      } = this.state;
      const payment = function (data, actions) {
            var apitoken = localStorage.getItem("apitoken")

            return actions.request.post(window.siteurl+'/venda/create-payment', {
              id_produto: window.produto_estado.id,
              preco: window.produto_estado.preco,
            }, 

            {
                headers: {
                    'Authorization': 'Bearer '+apitoken
                }
            })
            .then(function(res) {
              // 3. Return res.id from the response
              return res.id;
            });
        }
      const onAuthorize = function (data, actions) {
            var apitoken = localStorage.getItem("apitoken")

            var comissoes = JSON.parse(localStorage.getItem("comissoes"))
            var object = {};
            var produto = window.produto_estado

            if(comissoes && comissoes[produto.id]){
              object["id_comissionamento"] = comissoes[produto.id]
            }
            else{
              object["id_comissionamento"] = 0
            }

            return actions.request.post(window.siteurl+'/venda/execute-payment', {
              id_produto: window.produto_estado.id,
              endereco: window.endereco_venda,
              paymentID: data.paymentID,
              payerID:   data.payerID,
              id_comissionamento: object["id_comissionamento"],
            },
            {
                headers: {
                    'Authorization': 'Bearer '+apitoken
                }
            })
            .then(function(res) {
              onSuccess();
            });
          }
      return (
        <div>
          {showButton && <paypal.Button.react
            env={env}
            client={client}
            commit={commit}
            payment={payment}
            onAuthorize={onAuthorize}
            onCancel={onCancel}
            onError={onError}
          />}
        </div>
      );
    }
}
export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);