import React, { Component } from 'react';
import Navbar from '../partials/Navbar';

import M from "materialize-css";

class CadastroProduto extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);
    this.state = {
      categorias: []
    };
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))

      if(!usuario){
          this.props.history.push('/')
      }
   }

   componentDidMount(){
    fetch(window.siteurl + "/categoria/listar")
      .then(res => res.json())
      .then(
        (result) => {
          if(result.categorias){
            this.setState({
               categorias: result.categorias
            })

            M.AutoInit();
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )
   }

   cadastrar(event){
    var apitoken = localStorage.getItem("apitoken")

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    fetch(window.siteurl + "/produto/cadastrar", {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer '+apitoken
        },
        body: formData,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            var usuario = JSON.parse(localStorage.getItem("current_user"))
            este.props.history.push('/usuario/loja/' + usuario.id)
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   render() {
    const { categorias } = this.state
   return (
      <div>
        <Navbar></Navbar>
        <div className="container">
            <h1>Cadastrar produto</h1>
            <form encType="multipart/form-data" method="post" onSubmit={this.cadastrar}>
                    <label htmlFor="nome">Nome</label><br/>
                    <input id="nome" name="nome" required size="32" type="text" /><br/>

                    <p>
                      <label htmlFor="id_categoria">Categoria</label>
                      <select id="id_categoria" name="id_categoria">
                          {categorias.map(categoria => (
                              <option key={categoria.id} value={categoria.id}>{categoria.nome}</option>
                          ))}
                      </select>
                    </p>

                    <label htmlFor="descricao">Descrição</label>
                    <textarea id="descricao" name="descricao" className="materialize-textarea"></textarea>

                    <label htmlFor="tags">Tags</label><br/>
                    <input id="tags" name="tags" required size="32" type="text" /><br/>

                    <label htmlFor="file">Foto</label><br/>
                    <input id="foto" name="foto" size="32" type="file" accept="image/png, image/jpeg, image/gif" /><br/>

                    <label htmlFor="preco">Preço</label><br/>
                    <input id="preco" name="preco" required size="32" type="number" /><br/>

                    <label htmlFor="comissao">Percentual da comissão</label><br/>
                    <input id="comissao" name="comissao" required size="32" type="number" />

                    <button className="btn waves-effect waves-light red accent-4" type="submit" id="enviar" name="action">Cadastrar
                            <i className="material-icons right">send</i>
                    </button>
            </form>
        </div>
      </div>
   );
   }
}


export default CadastroProduto;