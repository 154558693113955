import React, { Component } from 'react';
import Navbar from '../partials/Navbar';
import PaypalButton from './partials/PaypalButton';


const CLIENT = {
  sandbox: 'AQrIi3EegUyPBwHEVg6Nsk3i1D63EBBKcT2bCttOX6bIrjGpH5CqFi9tx0JT6362db5Ec85E_6TPeBg9',
  production: 'AQrIi3EegUyPBwHEVg6Nsk3i1D63EBBKcT2bCttOX6bIrjGpH5CqFi9tx0JT6362db5Ec85E_6TPeBg9',
};

const ENV = 'sandbox'


class CadastroVenda extends Component {

  constructor() {
    super();
    this.cadastrar = this.cadastrar.bind(this);

    this.state = {
      produto: {comissao:0, preco:0, nome:'', descricao:'', 
        id_categoria:0, id_vendedor:0, tags:'', foto:''}
    };
    
  }

  componentWillMount(){
      var usuario = JSON.parse(localStorage.getItem("current_user"))
      var id_produto = this.props.match.params.id_produto

      if(!usuario){
        localStorage.setItem("last_screen", "/venda/cadastro/"+id_produto)
          this.props.history.push('/usuario/login')
      }

      var este = this
      fetch(window.siteurl + "/produto/ver/"+id_produto)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.produto){
              this.setState({
                 produto: result.produto
              })
            }
            else{
              este.props.history.push('/')
            }
          },
          (error) => {
            alert(error)
          }
        )
   }

   cadastrar(event){
    var apitoken = localStorage.getItem("apitoken")
    var comissoes = JSON.parse(localStorage.getItem("comissoes"))
    const { produto } = this.state

    event.preventDefault();

    const este = this;

    var formData = new FormData(event.target);

    var object = {};
    formData.forEach((value, key) => {object[key] = value});

    if(comissoes && comissoes[produto.id]){
      object["id_comissionamento"] = comissoes[produto.id]
    }
    else{
      object["id_comissionamento"] = 0
    }

    var data = JSON.stringify(object);

    fetch(window.siteurl + "/venda/cadastrar", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+apitoken
        },
        body: data,
      })
      .then(res => res.json())
      .then(
        (result) => {
          if(result.sucesso){
            este.props.history.push('/')
          }
          else{
            alert(result.msg)
          }
        },
        (error) => {
          alert(error)
        }
      )

   }

   handleChange(event){
      window.endereco_venda = event.target.value
   }

   render() {

    var produto = this.state.produto
    window.produto_estado = this.state.produto
    
    const onSuccess = function() {
      alert('Pagamento finalizado!');
      window.location.href="/usuario/eu";
    }
    const onError = (error) =>
      console.log('Erroneous payment OR failed to load script!', error);
    const onCancel = (data) =>
      console.log('Cancelled payment!', data);
   return (
      <div>
        <Navbar></Navbar>
        <div className="container center-align">
            <h1>Comprar {produto.nome}</h1>
            <div className="row">
                  <div className="col s12 m6">
                    <div className="card">
                      <div className="card-image">
                        <div className="white center-align" style={{height:'400px', width: '100%'}}>
                          <img src={window.siteurl + produto.foto} alt={produto.nome} style={{display: 'inline', height:'100%', width:'auto', maxWidth:'100%'}}/>
                        </div>
                      </div>
                      <div className="card-content">
                        <span className="card-title" style={{color:'black'}}>
                            <strong>{produto.nome}</strong>
                        </span>
                        <h4>
                          <strong>R${produto.preco}</strong>
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div className="col s12 m6">
                      <div className="valign-wrapper" style={{height: '100%'}}>
                          <form onSubmit={this.cadastrar}>
                                  <input type="hidden" name="preco" value={produto.preco} />
                                  <input type="hidden" name="id_produto" value={produto.id} />
                                  <label htmlFor="endereco">Endereço de entrega</label>
                                  <textarea id="endereco" name="endereco" onChange={(e) => this.handleChange(e)} className="materialize-textarea"></textarea>
                                  <PaypalButton
                                    client={CLIENT}
                                    env={ENV}
                                    commit={true}
                                    currency={'BRL'}
                                    total={0.01}
                                    onSuccess={onSuccess}
                                    onError={onError}
                                    onCancel={onCancel}
                                  />
                          </form>
                      </div>
                  </div>
            </div>
        </div>
      </div>
   )

   }
}


export default CadastroVenda;