import React, { Component } from 'react';

import M from "materialize-css";

class Galeria extends React.Component{

  constructor() {
      super();
      this.getFotos = this.getFotos.bind(this);
      this.enviarFoto = this.enviarFoto.bind(this);
      this.state = {fotos: []};
  }

  componentDidMount(){
      var elems = document.querySelectorAll('.modal');
      var instances = M.Modal.init(elems, {'onOpenEnd': this.getFotos});      
  }

  getFotos(){
      var id_produto = this.props.produto.id
      var fotos = this.state.fotos

      fetch(window.siteurl + "/produto/galeria/listar/"+id_produto)
        .then(res => res.json())
        .then(
          (result) => {
            if(result.fotos){
                this.setState({
                   fotos: result.fotos
                })

                var elems = document.querySelectorAll('.carousel');
                var instances = M.Carousel.init(elems, {});

                elems = document.querySelectorAll('.materialboxed');
                instances = M.Materialbox.init(elems, {});
            }
          },
          (error) => {
            alert(error)
          }
        ) 
  }

  carrossel(){
      var fotos = this.state.fotos

      if(fotos.length > 0){
          if(fotos.length === 2){
            fotos = fotos.concat(fotos)
          }
          
          return(
              <React.Fragment>
                <div className="carousel" id="galeria">
                      {fotos.map(foto => (
                        <a key={foto.id} className="carousel-item" href="#">
                          <img alt="Foto do produto" width="250px" height="250px" className="materialboxed" src={window.siteurl + foto.src}/>
                        </a>
                      ))}
                </div>
              </React.Fragment>
              )
      }
      else{
         return (
            <React.Fragment>
              <div className="center-align">
                  <h2>Não existem outras imagens do produto!</h2>
              </div>
            </React.Fragment>
          )
      }
  }

  enviarFoto(event){
      var apitoken = localStorage.getItem("apitoken")

      event.preventDefault();

      var formData = new FormData(event.target);

      fetch(window.siteurl + "/produto/galeria/adicionar", {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer '+apitoken
          },
          body: formData,
        })
        .then(res => res.json())
        .then(
          (result) => {
            if(result.sucesso){
              window.location.reload()
            }
            else{
              alert(result.msg)
            }
          },
          (error) => {
            alert(error)
          }
        )
  }

  formAdicionar(){
        var usuario = JSON.parse(localStorage.getItem("current_user"))
        var produto = this.props.produto

        if(usuario && (usuario.id == produto.id_vendedor || usuario.admin)){
            return (
                  <React.Fragment>
                    <form onSubmit={this.enviarFoto} enctype="multipart/form-data">
                        <input type="hidden" id="id_produto" name="id_produto" value={produto.id} />
                        <input type="file" name="foto" required id="foto" accept="image/png, image/jpeg, image/gif" />
                        <button className="btn-flat red accent-4" type="submit" name="action">
                                Adicionar foto<i class="material-icons right">send</i>
                        </button>
                    </form>
                  </React.Fragment>
              )
        }
        else{
            return null
        }
  }

  render(){
      var produto = this.props.produto
      return (
          <React.Fragment>
          <button data-target="maisimagens" style={{marginBottom:'10px'}} className="waves-effect btn-large red lighten-5 black-text modal-trigger">Ver mais imagens</button>
          <div id="maisimagens" className="modal">
            <div className="modal-content center-align red darken-3">
                <div className="right-align">
                  <a href="#!" className="modal-close btn-flat white black-text"><i className="material-icons center">close</i></a>
                </div>
                <h4>Imagens do produto</h4>
                { this.carrossel() }
                { this.formAdicionar() }
            </div>
          </div>
          </React.Fragment>
      )
  }
}

export default Galeria;